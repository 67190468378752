<template>
  <div>
    <router-view />
    <div class="background"></div>
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Gilead Korea MI HCP Registration",
      // all titles will be injected into this template
    };
  },
  created() {
    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    mq.addEventListener("change", (e) => {
      console.log(mq);
      this.$vuetify.theme.dark = e.matches;
    });
    this.getRouterParams();
    console.log(this.$i18n.locale);
  },
  methods: {
    getRouterParams() {
      // console.log(this.$router.currentRoute.query);
    },
  },
};
</script>