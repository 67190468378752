/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import authenticate from "@/auth/authenticate";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    // {
    //   path: "/",
    //   component: () => import("@/views/app/Index"),
    //   beforeEnter: authenticate, // 로그인 확인 
    //   redirect: "/app",
    //   children: [
    //     // {
    //     //   path: "layout/:layout",
    //     //   component: () => import("@/views/app/Index")
    //     // },
    //     {
    //       path: "/app",
    //       component: () => import("@/views/app/SamplePage"),
    //     },
    //   ]
    // },
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      name: "login",
      redirect: "/login",
      children: [
        // {
        //   path: "layout/:layout",
        //   component: () => import("@/views/app/Index")
        // },
        {
          path: "/login",
          component: () => import("@/views/app/login/MemberLoginPage"),
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      name: "register",
      children: [
        // {
        //   path: "layout/:layout",
        //   component: () => import("@/views/app/Index")
        // },
        {
          path: "/register",
          component: () => import("@/views/app/register/RegisterPage"),
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      // name: "FindIdPass",
      children: [
        // {
        //   path: "layout/:layout",
        //   component: () => import("@/views/app/Index")
        // },
        {
          name: "FindIdPass",
          path: "/FindIdPass",
          component: () => import("@/views/app/login/FindIdPassPage"),
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      name: "MyInfo",
      children: [
        {
          path: "/MyInfo",
          component: () => import("@/views/app/myInfo/MyInfoPage"),
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      beforeEnter: authenticate,
      name: "MembersLogged",
      children: [
        // {
        //   path: "layout/:layout",
        //   component: () => import("@/views/app/Index")
        // },
        {
          path: "/MembersLogged",
          component: () => import("@/views/app/members/MembersLoggedComponent"),
        },
      ]
    },
  ]
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch("changeThemeLoadingState", true);
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.

  setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
